"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDrawerContent = exports.setOpen = exports.drawerSlice = void 0;
const constants_1 = require("@appConstants/constants");
const toolkit_1 = require("@reduxjs/toolkit");
const drawerData_1 = require("@services/drawerData");
const react_toastify_1 = require("react-toastify");
const initialState = {
    open: false,
    drawerContent: undefined,
    getDrawerContentStatus: constants_1.REQUEST_STATUS.IDLE,
};
exports.drawerSlice = (0, toolkit_1.createSlice)({
    name: 'Drawer Slice',
    initialState: initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload;
        },
        setDrawerContent: (state, action) => {
            state.drawerContent = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(drawerData_1.getDrawerData.pending, (state) => {
            state.getDrawerContentStatus = constants_1.REQUEST_STATUS.PENDING;
        });
        builder.addCase(drawerData_1.getDrawerData.fulfilled, (state, action) => {
            var _a;
            state.getDrawerContentStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.drawerContent = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data;
        });
        builder.addCase(drawerData_1.getDrawerData.rejected, (state) => {
            state.getDrawerContentStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)(`Error! Can't fetch the Patient data`, {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
_a = exports.drawerSlice.actions, exports.setOpen = _a.setOpen, exports.setDrawerContent = _a.setDrawerContent;
exports.default = exports.drawerSlice.reducer;
