"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.installService = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const initialize_instance_1 = require("@apis/initialize.instance");
exports.installService = (0, toolkit_1.createAsyncThunk)('syncServiceForceInstall/installService', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.syncServiceForceInstallInstance.post(`/force-install/${selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id}`, {}, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 204) {
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
