"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDays = exports.CODE_TYPE = exports.FREQUENCY_OPTIONS = void 0;
exports.FREQUENCY_OPTIONS = [
    { display: 'Day', value: 'day' },
    { display: 'Week', value: 'week' },
    { display: 'Month', value: 'month' },
];
exports.CODE_TYPE = [
    {
        id: 'all-appointments',
        display: 'All Appointments',
        value: 'All Appointments',
    },
    { id: 'custom', display: 'Custom Treatments', value: 'Custom Treatments' },
];
const calculateDays = (frequency, frequencyType) => {
    let numberOfDays = 0;
    switch (frequencyType) {
        case 'day':
            numberOfDays = frequency;
            break;
        case 'week':
            numberOfDays = frequency * 7;
            break;
        case 'month':
            numberOfDays = frequency * 30;
            break;
        default:
            throw new Error(`Unsupported frequencyType: ${frequencyType}`);
    }
    return numberOfDays;
};
exports.calculateDays = calculateDays;
