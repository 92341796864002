"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.providersSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const providers_1 = require("@services/providers");
const constants_1 = require("@appConstants/constants");
const initialState = {
    getProvidersStatus: constants_1.REQUEST_STATUS.PENDING,
    providers: [],
};
exports.providersSlice = (0, toolkit_1.createSlice)({
    name: 'providers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(providers_1.fetchProviders.pending, (state) => {
            state.getProvidersStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(providers_1.fetchProviders.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.providers = action.payload;
                state.getProvidersStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getProvidersStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the providers', {
                    type: 'error',
                });
            }
        })
            .addCase(providers_1.fetchProviders.rejected, (state) => {
            state.getProvidersStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the providers', {
                type: 'error',
            });
        });
    },
});
// // Action creators are generated for each case reducer function
// export const {} = providersSlice.actions;
exports.default = exports.providersSlice.reducer;
