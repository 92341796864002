"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissionsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const constants_1 = require("@appConstants/constants");
const permissions_1 = require("@services/permissions");
const defaultPermissions_1 = require("@appConstants/defaultPermissions");
const initialState = {
    tenantPermissions: undefined,
    tenantPermissionsLoading: constants_1.REQUEST_STATUS.IDLE,
};
exports.permissionsSlice = (0, toolkit_1.createSlice)({
    name: 'permissions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(permissions_1.fetchPermissions.pending, (state) => {
            state.tenantPermissions = undefined;
            state.tenantPermissionsLoading = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(permissions_1.fetchPermissions.fulfilled, (state, action) => {
            if (action.payload && action.payload.features.length > 0) {
                state.tenantPermissions = action.payload;
            }
            else {
                state.tenantPermissions = {
                    tenantId: action.meta.arg.tenantId,
                    features: defaultPermissions_1.defaultPermissions,
                };
            }
            state.tenantPermissionsLoading = constants_1.REQUEST_STATUS.SUCCEEDED;
        })
            .addCase(permissions_1.fetchPermissions.rejected, (state) => {
            state.tenantPermissions = undefined;
            state.tenantPermissionsLoading = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the Permissions details', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(permissions_1.updatePermissions.pending, (state) => {
            state.tenantPermissionsLoading = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(permissions_1.updatePermissions.fulfilled, (state, action) => {
            if (action.payload) {
                state.tenantPermissions = action.payload;
                (0, react_toastify_1.toast)('Permissions have been updated successfully', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.tenantPermissions = undefined;
                (0, react_toastify_1.toast)('Error updating the Permissions details', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
            state.tenantPermissionsLoading = constants_1.REQUEST_STATUS.SUCCEEDED;
        })
            .addCase(permissions_1.updatePermissions.rejected, (state) => {
            state.tenantPermissionsLoading = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error updating the Permissions details', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
exports.default = exports.permissionsSlice.reducer;
