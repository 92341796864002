"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WEBAPP_VERSION = exports.THIRTY_MINUTES = exports.ONE_MINUTE = exports.FIVE_SECOND = exports.THREE_SECOND = exports.ONE_SECOND = exports.TreatPlanStatus = exports.PERSISTANT_CONNECTION_STATUS = exports.GLOBAL_ROLES = exports.REQUEST_STATUS = exports.AUTH_SCOPES = exports.AUTH_AUDIENCE = void 0;
exports.AUTH_AUDIENCE = process.env.AUTH_AUDIENCE || '';
exports.AUTH_SCOPES = 'site_admin account_admin basic_user';
var REQUEST_STATUS;
(function (REQUEST_STATUS) {
    REQUEST_STATUS[REQUEST_STATUS["IDLE"] = 0] = "IDLE";
    REQUEST_STATUS[REQUEST_STATUS["PENDING"] = 1] = "PENDING";
    REQUEST_STATUS[REQUEST_STATUS["FAILED"] = 2] = "FAILED";
    REQUEST_STATUS[REQUEST_STATUS["SUCCEEDED"] = 3] = "SUCCEEDED";
})(REQUEST_STATUS || (exports.REQUEST_STATUS = REQUEST_STATUS = {}));
var GLOBAL_ROLES;
(function (GLOBAL_ROLES) {
    GLOBAL_ROLES[GLOBAL_ROLES["NO_ROLE"] = 0] = "NO_ROLE";
    GLOBAL_ROLES[GLOBAL_ROLES["BASIC_USER"] = 1] = "BASIC_USER";
    GLOBAL_ROLES[GLOBAL_ROLES["ACCOUNT_ADMIN"] = 2] = "ACCOUNT_ADMIN";
    GLOBAL_ROLES[GLOBAL_ROLES["SITE_ADMIN"] = 3] = "SITE_ADMIN";
    GLOBAL_ROLES[GLOBAL_ROLES["CHOOSE_ROLE"] = 4] = "CHOOSE_ROLE";
})(GLOBAL_ROLES || (exports.GLOBAL_ROLES = GLOBAL_ROLES = {}));
var PERSISTANT_CONNECTION_STATUS;
(function (PERSISTANT_CONNECTION_STATUS) {
    PERSISTANT_CONNECTION_STATUS[PERSISTANT_CONNECTION_STATUS["CONNECTED"] = 0] = "CONNECTED";
    PERSISTANT_CONNECTION_STATUS[PERSISTANT_CONNECTION_STATUS["CLOSED"] = 1] = "CLOSED";
    PERSISTANT_CONNECTION_STATUS[PERSISTANT_CONNECTION_STATUS["NEEDS_AUTH"] = 2] = "NEEDS_AUTH";
})(PERSISTANT_CONNECTION_STATUS || (exports.PERSISTANT_CONNECTION_STATUS = PERSISTANT_CONNECTION_STATUS = {}));
var TreatPlanStatus;
(function (TreatPlanStatus) {
    TreatPlanStatus[TreatPlanStatus["Saved"] = 0] = "Saved";
    TreatPlanStatus[TreatPlanStatus["Active"] = 1] = "Active";
    TreatPlanStatus[TreatPlanStatus["Inactive"] = 2] = "Inactive";
})(TreatPlanStatus || (exports.TreatPlanStatus = TreatPlanStatus = {}));
exports.ONE_SECOND = 1000;
exports.THREE_SECOND = exports.ONE_SECOND * 3;
exports.FIVE_SECOND = exports.ONE_SECOND * 5;
exports.ONE_MINUTE = exports.ONE_SECOND * 60;
exports.THIRTY_MINUTES = exports.ONE_MINUTE * 30;
exports.WEBAPP_VERSION = 'v2.1.0';
