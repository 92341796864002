"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notesSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const constants_1 = require("@appConstants/constants");
const notes_1 = require("@services/notes");
const initialState = {
    notes: [],
    notesStatus: constants_1.REQUEST_STATUS.IDLE,
    createNotesStatus: constants_1.REQUEST_STATUS.IDLE,
};
exports.notesSlice = (0, toolkit_1.createSlice)({
    name: 'notes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(notes_1.fetchNotes.pending, (state) => {
            state.notes = [];
            state.notesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(notes_1.fetchNotes.fulfilled, (state, action) => {
            if (action.payload && action.payload.length > 0) {
                state.notes = action.payload;
            }
            state.notesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
        })
            .addCase(notes_1.fetchNotes.rejected, (state) => {
            state.notes = [];
            state.notesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the notes', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(notes_1.AddNote.pending, (state) => {
            state.createNotesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(notes_1.AddNote.fulfilled, (state) => {
            state.createNotesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('The Note added Successfully', {
                type: 'success',
                autoClose: 5000,
            });
        })
            .addCase(notes_1.AddNote.rejected, (state) => {
            state.createNotesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error creating the note', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
exports.default = exports.notesSlice.reducer;
