"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUnpaidAppointments = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const initialize_instance_1 = require("@apis/initialize.instance");
exports.fetchUnpaidAppointments = (0, toolkit_1.createAsyncThunk)('unPaidAppointments/fetchAppointment', ({ PatientId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const storeState = thunkApi.getState();
    const { tenantId, token, currentUser } = storeState.currentUserReducer;
    if (!tenantId) {
        return Promise.reject('noTenantId');
    }
    if (!token) {
        return Promise.reject('noToken');
    }
    if (!currentUser) {
        return Promise.reject('noCurrentUser');
    }
    const response = yield initialize_instance_1.unpaidAppointmentInstance.get(`${tenantId}/${PatientId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
    if (response.status === 200) {
        return response.data;
    }
    return null;
}));
