"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorTypography = exports.SecondaryTypography = exports.MainTypography = void 0;
const material_1 = require("@mui/material");
const styles_1 = require("@mui/material/styles");
exports.MainTypography = (0, styles_1.styled)(material_1.Typography)(({ theme }) => ({
    color: theme.palette.custom.dark01,
}));
exports.SecondaryTypography = (0, styles_1.styled)(material_1.Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
}));
exports.ErrorTypography = (0, styles_1.styled)(material_1.Typography)(() => ({
    backgroundColor: 'red',
    color: 'white',
    padding: '2px 7px',
    borderRadius: '7px',
    fontSize: '12px',
}));
