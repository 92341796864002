"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchAllReceipt = exports.fetchTerminalsList = exports.processPayment = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const initialize_instance_1 = require("@apis/initialize.instance");
exports.processPayment = (0, toolkit_1.createAsyncThunk)('payment/processPayment', (payload, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const storeState = thunkApi.getState();
    const { tenantId, token, currentUser } = storeState.currentUserReducer;
    if (!currentUser) {
        return thunkApi.rejectWithValue('noCurrentUser');
    }
    if (!tenantId) {
        return thunkApi.rejectWithValue('noTenantId');
    }
    if (!token) {
        return thunkApi.rejectWithValue('noToken');
    }
    try {
        const response = yield initialize_instance_1.paymentsInstance.put(`/process-payment/${tenantId}`, Object.assign({}, payload), (0, axiosHelper_1.addTokenToConfig)({}, token));
        return (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data;
    }
    catch (error) {
        const axiosError = error;
        console.error('Error processing payment:', axiosError);
        const errorMessage = ((_b = axiosError.response) === null || _b === void 0 ? void 0 : _b.data) || axiosError.message || 'Error processing payment';
        return thunkApi.rejectWithValue(errorMessage);
    }
}));
exports.fetchTerminalsList = (0, toolkit_1.createAsyncThunk)('payment/terminalsList', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const storeState = thunkApi.getState();
    const { tenantId, currentUser, token } = storeState.currentUserReducer;
    if (!currentUser) {
        return Promise.reject('noCurrentUser');
    }
    if (!tenantId) {
        return Promise.reject('noTenantId');
    }
    if (!token) {
        return Promise.reject('noToken');
    }
    const response = yield initialize_instance_1.terminalInstance.get(`/get-terminals/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
    return response === null || response === void 0 ? void 0 : response.data;
}));
exports.fetchAllReceipt = (0, toolkit_1.createAsyncThunk)('stripe/requestPayments', (payload, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const storeState = thunkApi.getState();
    const { tenantId, token, currentUser } = storeState.currentUserReducer;
    const queryParams = [];
    for (const key in payload) {
        if (Object.prototype.hasOwnProperty.call(payload, key)) {
            const value = payload[key];
            if (value) {
                queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        }
    }
    if (!currentUser) {
        return Promise.reject('noCurrentUser');
    }
    if (!tenantId) {
        return Promise.reject('noTenantId');
    }
    if (!token) {
        return Promise.reject('noToken');
    }
    const response = yield initialize_instance_1.paymentsInstance.get(`/get-receipts/${tenantId}?${queryParams.join('&')}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
    if (response && response.data) {
        return (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data;
    }
    return null;
}));
