"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddNote = exports.fetchNotes = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const initialize_instance_1 = require("@apis/initialize.instance");
exports.fetchNotes = (0, toolkit_1.createAsyncThunk)('notes/fetchNotes', ({ patientId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const storeState = thunkApi.getState();
    const { currentUser, token, tenantId } = storeState.currentUserReducer;
    if (!tenantId) {
        return Promise.reject('noTenantId');
    }
    if (!currentUser) {
        return Promise.reject('noCurrentUser');
    }
    if (!token) {
        return Promise.reject('noToken');
    }
    if (!patientId) {
        return Promise.reject('noPatientId');
    }
    const response = yield initialize_instance_1.notesInstance.get(`/${tenantId}/${patientId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
    if (response && response.data) {
        return response.data;
    }
    return;
}));
exports.AddNote = (0, toolkit_1.createAsyncThunk)('notes/AddNote', ({ patientId, type, mode, note, NoteSentOrReceived }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const storeState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const { currentUser, token, tenantId } = storeState.currentUserReducer;
    if (!tenantId) {
        return Promise.reject('noTenantId');
    }
    if (!currentUser) {
        return Promise.reject('noCurrentUser');
    }
    if (!token) {
        return Promise.reject('noToken');
    }
    if (!patientId) {
        return Promise.reject('noPatientId');
    }
    const response = yield initialize_instance_1.notesInstance.post(`/${tenantId}/${patientId}`, { patientId, type, mode, note, NoteSentOrReceived }, (0, axiosHelper_1.addTokenToConfig)({}, token));
    if (response && response.data) {
        dispatch((0, exports.fetchNotes)({ patientId }));
        return response.data;
    }
    return;
}));
