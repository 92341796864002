"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addTokenToConfig = void 0;
const addTokenToConfig = (config, token) => {
    if (!config) {
        config = {};
    }
    if (!config.headers) {
        config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
};
exports.addTokenToConfig = addTokenToConfig;
