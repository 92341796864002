"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.restoreUser = exports.fetchArchivedUsers = exports.deleteUsers = exports.updateUsers = exports.createUsers = exports.fetchUsers = exports.fetchBasicUsers = void 0;
/* eslint-disable no-useless-catch */
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const constants_1 = require("@appConstants/constants");
const initialize_instance_1 = require("@apis/initialize.instance");
exports.fetchBasicUsers = (0, toolkit_1.createAsyncThunk)('users/fetchBasicUsers', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser, tenantId } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.tenantInstance.get(`/users/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data && Array.isArray(response.data)) {
            const list = response.data;
            const users = list.map((user) => {
                user.themeColor = '#00abb3';
                return user;
            });
            return users;
        }
        return null;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchUsers = (0, toolkit_1.createAsyncThunk)('users/fetchUsers', ({ tenantId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser, globalRole } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        let url = ``;
        if (tenantId) {
            url = url + `/${tenantId}`;
        }
        if (globalRole === constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            const response = yield initialize_instance_1.adminInstance.get(`users${url}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
            return response.data;
        }
        else {
            const response = yield initialize_instance_1.tenantInstance.get(`users${url}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
            return response.data;
        }
    }
    catch (err) {
        throw err;
    }
}));
exports.createUsers = (0, toolkit_1.createAsyncThunk)('users/createUsers', (user, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, tenantId, currentUser, globalRole } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!tenantId && globalRole !== constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        let response;
        if (globalRole === constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            response = yield initialize_instance_1.adminInstance.post(`users/create-user`, { name: user.name, email: user.email }, (0, axiosHelper_1.addTokenToConfig)({}, token));
        }
        else {
            response = yield initialize_instance_1.tenantInstance.post(`users`, { name: user.name, email: user.email }, (0, axiosHelper_1.addTokenToConfig)({ headers: { tenantId } }, token));
        }
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateUsers = (0, toolkit_1.createAsyncThunk)('users/updateUsers', (user, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, tenantId, currentUser, globalRole } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!tenantId && globalRole !== constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        let response;
        if (globalRole === constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            response = yield initialize_instance_1.adminInstance.patch(`users/update-user/${user._id}`, { name: user.name, email: user.email }, (0, axiosHelper_1.addTokenToConfig)({ headers: { tenantId } }, token));
        }
        else {
            response = yield initialize_instance_1.tenantInstance.patch(`users/${user._id}`, { name: user.name, email: user.email }, (0, axiosHelper_1.addTokenToConfig)({ headers: { tenantId } }, token));
        }
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.deleteUsers = (0, toolkit_1.createAsyncThunk)('users/deleteUsers', (user, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser, globalRole } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        let response;
        if (globalRole === constants_1.GLOBAL_ROLES.SITE_ADMIN) {
            response = yield initialize_instance_1.adminInstance.delete(`users/${user.email}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        }
        else {
            response = yield initialize_instance_1.tenantInstance.delete(`users/${user.email}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        }
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchArchivedUsers = (0, toolkit_1.createAsyncThunk)('archivedUsers', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.get('/users/archived/user', (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (error) {
        throw error;
    }
}));
exports.restoreUser = (0, toolkit_1.createAsyncThunk)('restoreUser', (id, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const dispatch = thunkApi.dispatch;
        const { token, currentUser, tenantId } = storeState.currentUserReducer;
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        const response = yield initialize_instance_1.adminInstance.patch(`/users/restore/${id}`, {}, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data.result) {
            dispatch((0, exports.fetchArchivedUsers)());
            dispatch((0, exports.fetchUsers)({ tenantId }));
        }
        return response.data.result;
    }
    catch (error) {
        throw error;
    }
}));
