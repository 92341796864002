"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CODE_TYPE = exports.FREQUENCY_OPTIONS = exports.RETENTION_TEXT_OPTIONS = void 0;
exports.RETENTION_TEXT_OPTIONS = [
    {
        value: 'TimeSinceLastClean',
        display: 'Time Since Last Cleaning',
        sample: '4 Months Ago',
    },
    {
        value: 'TimeSinceLastCheckup',
        display: 'Time Since Last Checkup',
        sample: '5 Months Ago',
    },
    {
        value: 'TimeSinceLastAppointment',
        display: 'Time Since Last Appointment',
        sample: '2 Weeks Ago',
    },
];
exports.FREQUENCY_OPTIONS = [
    { display: '1 week', value: 7 },
    { display: '2 week', value: 14 },
    { display: '1 month', value: 30 },
    { display: '2 month', value: 60 },
    { display: '3 month', value: 90 },
    { display: '6 month', value: 180 },
];
exports.CODE_TYPE = [
    {
        id: 'all-appointments',
        display: 'All Appointments',
        value: 'All Appointments',
    },
    { id: 'cleaning', display: 'Cleaning', value: 'Cleaning' },
];
