import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';

import { store } from './redux/store';
import { register as registerServiceWorker } from './serviceWorkerRegistration';
import App from './App';

const domain = process.env.AUTH0_DOMAIN;
const clientId = process.env.AUTH0_CLIENTID;

const root = ReactDOM.createRoot(document.getElementById('global-root'));

root.render(
  <Provider store={store}>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}>
      <App />
    </Auth0Provider>
  </Provider>
);

if (process.env.NODE_ENV === 'production') {
  registerServiceWorker();
}
