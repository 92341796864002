"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultPermissionsForPayload = exports.defaultPermissions = void 0;
exports.defaultPermissions = [
    { name: 'Appointments', isEnable: false, dataSource: 'internal' },
    { name: 'Conversations', isEnable: false, dataSource: 'internal' },
    { name: 'Reminders&Notifications', isEnable: false, dataSource: 'internal' },
    { name: 'Forms', isEnable: false, dataSource: 'internal' },
    { name: 'TreatmentPlans', isEnable: false, dataSource: 'internal' },
    // { name: 'Invoices', isEnable: false, dataSource: 'internal' },
    { name: 'StatusMapper', isEnable: false, dataSource: 'internal' },
    { name: 'UserManagement', isEnable: false, dataSource: 'internal' },
    { name: 'Payments', isEnable: false, dataSource: 'internal' },
    { name: 'MissedCalls', isEnable: false, dataSource: 'internal' },
    { name: 'Analytics', isEnable: false, dataSource: 'internal' },
];
exports.defaultPermissionsForPayload = {
    Appointments: false,
    Conversations: false,
    Forms: false,
    MissedCalls: false,
    Payments: false,
    'Reminders&Notifications': false,
    StatusMapper: false,
    UserManagement: false,
    TreatmentPlans: false,
    Analytics: false,
};
