"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendFormToKiosk = exports.getFormKioskNumbers = exports.sendFormWithText = exports.updateDocumentType = exports.setFormType = exports.makeFormCompliant = exports.updateQuestion = exports.fetchFormMapping = exports.fetchFormExpandedData = exports.fetchDocumentTypes = exports.addFormToFolder = exports.createFormFolder = exports.fetchAvailableForms = exports.fetchTenantAvailableForms = exports.getCollectionById = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const initialize_instance_1 = require("@apis/initialize.instance");
const getCollectionById = (groupId) => __awaiter(void 0, void 0, void 0, function* () {
    return initialize_instance_1.formsInstance.get(`/patient-forms/${groupId}`);
});
exports.getCollectionById = getCollectionById;
exports.fetchTenantAvailableForms = (0, toolkit_1.createAsyncThunk)('forms/fetchTenatAvailableForms', ({ tenantId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.tenantFormsInstance.get(`${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchAvailableForms = (0, toolkit_1.createAsyncThunk)('forms/fetchAvailableForms', ({ tenantId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.adminFormsInstance.get(`${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.createFormFolder = (0, toolkit_1.createAsyncThunk)('forms/createFormFolder', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.adminFormsInstance.post(`create/${selectedTenant._id}`, {}, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            thunkApi.dispatch((0, exports.fetchAvailableForms)({ tenantId: selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id }));
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.addFormToFolder = (0, toolkit_1.createAsyncThunk)('forms/addFormToFolder', ({ formId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!formId) {
            return Promise.reject('noFormId');
        }
        const response = yield initialize_instance_1.adminFormsInstance.post(`assign-form/${selectedTenant._id}/${formId}`, {}, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            thunkApi.dispatch((0, exports.fetchAvailableForms)({ tenantId: selectedTenant._id }));
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchDocumentTypes = (0, toolkit_1.createAsyncThunk)('forms/fetchDocumentTypes', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.documentTypesInstance.get(`${selectedTenant._id}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            return response.data;
        }
        return null;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchFormExpandedData = (0, toolkit_1.createAsyncThunk)('forms/fetchFormExpandedData', ({ formId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!formId) {
            return Promise.reject('noFormId');
        }
        const response = yield initialize_instance_1.adminFormsInstance.get(`expanded/${selectedTenant._id}/${formId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            return response.data;
        }
        return null;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchFormMapping = (0, toolkit_1.createAsyncThunk)('forms/fetchFormMapping', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.adminFormsInstance.get('type-maps', (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            return response.data;
        }
        return null;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateQuestion = (0, toolkit_1.createAsyncThunk)('forms/updateQuestion', ({ formId, questionId, questionData, }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!formId) {
            return Promise.reject('noformId');
        }
        if (!questionId) {
            return Promise.reject('noquestionId');
        }
        if (!questionData) {
            return Promise.reject('noquestionData');
        }
        const response = yield initialize_instance_1.adminFormsInstance.post(`map-question/${selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id}/${formId}/${questionId}`, questionData, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            thunkApi.dispatch((0, exports.fetchFormExpandedData)({ formId }));
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.makeFormCompliant = (0, toolkit_1.createAsyncThunk)('forms/makeFormCompliant', ({ formId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!formId) {
            return Promise.reject('noformId');
        }
        const response = yield initialize_instance_1.adminFormsInstance.post(`init-update/${selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id}/${formId}`, {}, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            thunkApi.dispatch((0, exports.fetchFormExpandedData)({ formId }));
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.setFormType = (0, toolkit_1.createAsyncThunk)('forms/setFormType', ({ formId, formtype }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!formId) {
            return Promise.reject('noformId');
        }
        if (!formtype) {
            return Promise.reject('noformtype');
        }
        const response = yield initialize_instance_1.adminFormsInstance.post(`form-type/${selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id}/${formId}`, { formtype }, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            thunkApi.dispatch((0, exports.fetchFormExpandedData)({ formId }));
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.updateDocumentType = (0, toolkit_1.createAsyncThunk)('forms/updateDocumentType', ({ formId, documentType }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser } = storeState.currentUserReducer;
        const { selectedTenant } = storeState.tenantsReducer;
        if (!(selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id)) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!formId) {
            return Promise.reject('noformId');
        }
        if (!documentType) {
            return Promise.reject('nodocumentType');
        }
        const response = yield initialize_instance_1.adminFormsInstance.post(`document-type/${selectedTenant === null || selectedTenant === void 0 ? void 0 : selectedTenant._id}/${formId}`, { documentType }, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            thunkApi.dispatch((0, exports.fetchFormExpandedData)({ formId }));
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.sendFormWithText = (0, toolkit_1.createAsyncThunk)('forms/sendFormWithText', (formData, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser, tenantId } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.tenantFormsInstance.post(`sms/${tenantId}`, formData, (0, axiosHelper_1.addTokenToConfig)({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }, token));
        if (response.status === 200 && response.data) {
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.getFormKioskNumbers = (0, toolkit_1.createAsyncThunk)('kiosk/getFormKioskNumbers', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { token, currentUser, tenantId } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.tenantFormsInstance.get(`kiosk/${tenantId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data) {
            return response.data;
        }
        return [];
    }
    catch (err) {
        throw err;
    }
}));
exports.sendFormToKiosk = (0, toolkit_1.createAsyncThunk)('kiosk/sendFormToKiosk', ({ kioskId, type, formUrl, summary }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const storeState = thunkApi.getState();
    const { token, currentUser, tenantId } = storeState.currentUserReducer;
    if (!tenantId) {
        return Promise.reject('noTenantId');
    }
    if (!currentUser) {
        return Promise.reject('noCurrentUser');
    }
    if (!token) {
        return Promise.reject('noToken');
    }
    const response = yield initialize_instance_1.kioskInstance.post(`kiosk/${tenantId}`, { kioskId, formUrl, type, summary }, (0, axiosHelper_1.addTokenToConfig)({}, token));
    if (response.status === 200 && response.data && response.data.success) {
        return true;
    }
    return false;
}));
