"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPaymentsAnalytics = exports.resetPayments = exports.paymentsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("@appConstants/constants");
const payment_1 = require("@services/payment");
const react_toastify_1 = require("react-toastify");
const initialState = {
    paymentStatus: constants_1.REQUEST_STATUS.IDLE,
    terminalStatus: constants_1.REQUEST_STATUS.IDLE,
    terminalsList: [],
    paymentResponseData: undefined,
    invoiceStatus: constants_1.REQUEST_STATUS.IDLE,
    invoicesList: [],
    haveMore: true,
};
exports.paymentsSlice = (0, toolkit_1.createSlice)({
    name: 'payment',
    initialState,
    reducers: {
        resetPayments: (state) => {
            state.terminalStatus = constants_1.REQUEST_STATUS.IDLE;
            state.paymentStatus = constants_1.REQUEST_STATUS.IDLE;
        },
        resetPaymentsAnalytics: (state) => {
            state.invoiceStatus = constants_1.REQUEST_STATUS.IDLE;
            state.invoicesList = [];
            state.haveMore = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(payment_1.processPayment.pending, (state) => {
            state.paymentStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(payment_1.processPayment.fulfilled, (state, action) => {
            if (action.payload) {
                state.paymentResponseData = action.payload;
                state.paymentStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                console.log('error in slice :', action);
                state.paymentStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error in processing payment', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(payment_1.processPayment.rejected, (state, action) => {
            console.log('error in slice rejected:', action);
            const data = action.payload;
            state.paymentStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)(data.message, {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(payment_1.fetchTerminalsList.pending, (state) => {
            state.terminalStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(payment_1.fetchTerminalsList.fulfilled, (state, action) => {
            if (action.payload.terminals) {
                state.terminalStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                state.terminalsList = action.payload.terminals;
            }
            else {
                state.terminalStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error in Fetching Terminals', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(payment_1.fetchTerminalsList.rejected, (state) => {
            state.terminalStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error in Fetching Terminals', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(payment_1.fetchAllReceipt.pending, (state) => {
            state.invoiceStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(payment_1.fetchAllReceipt.fulfilled, (state, action) => {
            if (action.payload) {
                state.invoiceStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                const paymentList = [...state.invoicesList, ...action.payload.receipts];
                if (paymentList.length > 0) {
                    const key = '_id';
                    const unique = [...new Map(paymentList.map((item) => [item[key], item])).values()];
                    state.invoicesList = unique;
                    state.haveMore = action.payload.hasMore;
                }
            }
            else {
                state.invoiceStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error in Fetching Payment Analytics', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(payment_1.fetchAllReceipt.rejected, (state) => {
            state.invoiceStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error in Fetching Payment Analytics', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
_a = exports.paymentsSlice.actions, exports.resetPayments = _a.resetPayments, exports.resetPaymentsAnalytics = _a.resetPaymentsAnalytics;
exports.default = exports.paymentsSlice.reducer;
