"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = exports.sendMessage = void 0;
let socket;
const sendMessage = (messageType, message) => {
    if (socket) {
        Object.assign(message, { messageType });
        const msg = {
            event: 'ui',
            data: message,
        };
        socket.send(JSON.stringify(msg));
    }
};
exports.sendMessage = sendMessage;
const init = (tenantId, jwt, newMessageCallback, isKiosk, kioskNumber) => {
    if (process.env.WEBSOCKET_HOST) {
        socket = new WebSocket(process.env.WEBSOCKET_HOST);
    }
    socket.addEventListener('close', (event) => {
        console.log('Socket close: ', event);
        newMessageCallback({ MessageType: 'closed' });
    });
    socket.addEventListener('error', (event) => {
        console.log('Socket error: ', event);
        newMessageCallback({ MessageType: 'closed' });
    });
    socket.addEventListener('message', (event) => {
        const parsedMessage = JSON.parse(event.data);
        let connectionType = 'ui';
        if (isKiosk) {
            connectionType = 'ui-kiosk';
        }
        let message = parsedMessage.data;
        let sessionId = '';
        if (typeof message === 'string' && message.includes('{') && message.includes('}')) {
            message = JSON.parse(parsedMessage.data);
            sessionId = message.sessionId;
        }
        switch (parsedMessage.MessageType) {
            case 'init':
                (0, exports.sendMessage)('auth', {
                    jwt,
                    connectionType,
                    tenantId,
                    sessionId,
                    kioskNumber,
                });
                break;
            case 'checkPulse':
                (0, exports.sendMessage)('alive', { message: "I'm here." });
                break;
            default:
                newMessageCallback(parsedMessage);
                break;
        }
    });
};
exports.init = init;
