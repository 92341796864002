"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestPayments = exports.verifyStripeAccount = exports.generateInvoice = exports.createStripeAccount = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const conversations_1 = require("@services/conversations");
const initialize_instance_1 = require("@apis/initialize.instance");
exports.createStripeAccount = (0, toolkit_1.createAsyncThunk)('stripe/createStripeAccount', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token, currentUser } = storeState.currentUserReducer;
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.paymentInstance.post(`/create/${tenantId}`, { number: 'create account' }, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data;
    }
    catch (err) {
        throw err;
    }
}));
exports.generateInvoice = (0, toolkit_1.createAsyncThunk)('stripe/generateInvoice', (payload, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token, currentUser } = storeState.currentUserReducer;
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const { products } = payload;
        const productsWithNumberPrices = products.map((product) => {
            const priceValue = typeof product.price === 'number' ? product.price : parseFloat(product.price);
            return Object.assign(Object.assign({}, product), { price: priceValue });
        });
        const response = yield initialize_instance_1.invoiceInstance.post(`create/${tenantId}`, Object.assign(Object.assign({}, payload), { products: [...productsWithNumberPrices] }), (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response.status === 200 && response.data && response.data.data) {
            thunkApi.dispatch((0, conversations_1.sendMessageToPatient)({
                number: payload.patientPhoneNumber,
                message: response.data.data,
            }));
            return response.data.data;
        }
        return '';
    }
    catch (err) {
        throw err;
    }
}));
const verifyStripeAccount = (token, tenantId) => __awaiter(void 0, void 0, void 0, function* () {
    return initialize_instance_1.paymentInstance
        .post(`verify/${tenantId}`, { number: 'create account' }, (0, axiosHelper_1.addTokenToConfig)({}, token))
        .then((response) => {
        return response.data.data;
    })
        .catch((err) => err);
});
exports.verifyStripeAccount = verifyStripeAccount;
exports.requestPayments = (0, toolkit_1.createAsyncThunk)('stripe/requestPayments', (payload, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    try {
        const storeState = thunkApi.getState();
        const { tenantId, token, currentUser } = storeState.currentUserReducer;
        const queryParams = [];
        for (const key in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, key)) {
                const value = payload[key];
                if (value) {
                    queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                }
            }
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        const response = yield initialize_instance_1.invoiceInstance.get(`/get/${tenantId}?${queryParams.join('&')}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response && response.data) {
            return (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data;
        }
        return null;
    }
    catch (err) {
        throw err;
    }
}));
