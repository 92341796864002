"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unscheduleTreatmentReminderMessageSlice = void 0;
const react_toastify_1 = require("react-toastify");
const toolkit_1 = require("@reduxjs/toolkit");
const unscheduleTreatmentReminder_1 = require("@services/unscheduleTreatmentReminder");
const constants_1 = require("@appConstants/constants");
const initialState = {
    unscheduleReminderMessages: [],
    unscheduleReminderMessagesStatus: constants_1.REQUEST_STATUS.PENDING,
};
exports.unscheduleTreatmentReminderMessageSlice = (0, toolkit_1.createSlice)({
    name: 'unscheduleTreatmentReminder',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(unscheduleTreatmentReminder_1.createUnscheduleTreatmentReminderMessage.pending, (state) => {
            state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(unscheduleTreatmentReminder_1.createUnscheduleTreatmentReminderMessage.fulfilled, (state, action) => {
            if (action.payload) {
                state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Create Unschedule Treatment Reminder Message Successfully', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error Creating  Unschedule Treatment Reminder Message', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(unscheduleTreatmentReminder_1.createUnscheduleTreatmentReminderMessage.rejected, (state) => {
            state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error Creating  Unschedule Treatment Reminder Message', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(unscheduleTreatmentReminder_1.fetchUnscheduleTreatmentReminderMessage.pending, (state) => {
            state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(unscheduleTreatmentReminder_1.fetchUnscheduleTreatmentReminderMessage.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                state.unscheduleReminderMessages = [...action.payload];
            }
            else {
                console.log('Else Action payload: ', action.payload);
                state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error Fetching  Unschedule Treatment Reminder Messages', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(unscheduleTreatmentReminder_1.fetchUnscheduleTreatmentReminderMessage.rejected, (state) => {
            state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error Fetching  Unschedule Treatment Reminder Messages', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(unscheduleTreatmentReminder_1.updateUnscheduleTreatmentReminderMessage.pending, (state) => {
            state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(unscheduleTreatmentReminder_1.updateUnscheduleTreatmentReminderMessage.fulfilled, (state, action) => {
            if (action.payload) {
                state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)(' Unschedule Treatment Reminder Message updated successfully', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)(' Unschedule Treatment Reminder Message Failed to Update', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(unscheduleTreatmentReminder_1.updateUnscheduleTreatmentReminderMessage.rejected, (state) => {
            state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)(' Unschedule Treatment Reminder Message Failed to Updated', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(unscheduleTreatmentReminder_1.deleteUnscheduleTreatmentReminderMessage.pending, (state) => {
            state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(unscheduleTreatmentReminder_1.deleteUnscheduleTreatmentReminderMessage.fulfilled, (state, action) => {
            if (action.payload) {
                state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)(' Unschedule Treatment Reminder Message Deleted successfully', {
                    type: 'success',
                    autoClose: 5000,
                });
            }
            else {
                state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)(' Unschedule Treatment Reminder Message Failed to Deleted', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(unscheduleTreatmentReminder_1.deleteUnscheduleTreatmentReminderMessage.rejected, (state) => {
            state.unscheduleReminderMessagesStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Unschedule Treatment Reminder Message Failed to Deleted', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
exports.default = exports.unscheduleTreatmentReminderMessageSlice.reducer;
