// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../public/fonts/Nunito-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../../public/fonts/Nunito-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:"Nunito";font-weight:normal;font-style:normal;src:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}@font-face{font-family:"Nunito-Bold";src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");font-weight:700;font-style:normal}html body{font-family:"Nunito",sans-serif}html body b,html body strong{font-family:"Nunito-Bold",sans-serif;font-weight:700}`, "",{"version":3,"sources":["webpack://./src/sass/global.scss"],"names":[],"mappings":"AAAA,WACE,oBAAA,CACA,kBAAA,CACA,iBAAA,CACA,2CAAA,CAEF,WACE,yBAAA,CACA,8DAAA,CACA,eAAA,CACA,iBAAA,CAGA,UACE,+BAAA,CACA,6BAEE,oCAAA,CACA,eAAA","sourcesContent":["@font-face {\r\n  font-family: 'Nunito';\r\n  font-weight: normal;\r\n  font-style: normal;\r\n  src: url('./../../public/fonts/Nunito-Regular.ttf');\r\n}\r\n@font-face {\r\n  font-family: 'Nunito-Bold';\r\n  src: url('./../../public/fonts/Nunito-Bold.ttf') format('truetype');\r\n  font-weight: 700;\r\n  font-style: normal;\r\n}\r\nhtml {\r\n  body {\r\n    font-family: 'Nunito', sans-serif;\r\n    b,\r\n    strong {\r\n      font-family: 'Nunito-Bold', sans-serif;\r\n      font-weight: 700;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
