"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatToTwoDecimalPoints = exports.getCurrentTime = exports.formatAmount = exports.validateEmail = exports.cleanNumber = exports.formatPhoneNumber = void 0;
const formatPhoneNumber = (rawNumber) => {
    let number = (0, exports.cleanNumber)(rawNumber);
    let newNumber = number;
    if (number) {
        if (number.length === 11) {
            if (number[0] === '1') {
                number = number.substring(1);
            }
        }
        if (number.length === 10) {
            newNumber = `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
        }
    }
    return newNumber;
};
exports.formatPhoneNumber = formatPhoneNumber;
const cleanNumber = (number) => {
    if (number) {
        return number.replace('+', '');
    }
    return number;
};
exports.cleanNumber = cleanNumber;
const validateEmail = (email) => {
    if (email.includes('@')) {
        const mainParts = email.split('@');
        if (mainParts.length === 2) {
            return mainParts[1].includes('.');
        }
    }
    return false;
};
exports.validateEmail = validateEmail;
const formatAmount = (amount) => {
    return `$${amount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`;
};
exports.formatAmount = formatAmount;
const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let period = 'AM';
    if (hours > 12) {
        hours -= 12;
        period = 'PM';
    }
    else if (hours === 12) {
        period = 'PM';
    }
    else if (hours === 0) {
        hours = 12;
    }
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${period}`;
};
exports.getCurrentTime = getCurrentTime;
const formatToTwoDecimalPoints = (value) => {
    if (value === null || value === undefined) {
        return '0.00';
    }
    if (typeof value === 'string') {
        const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
        if (isNaN(numericValue)) {
            return '0.00';
        }
        return numericValue.toFixed(2);
    }
    if (typeof value === 'number') {
        return value.toFixed(2);
    }
    return '0.00';
};
exports.formatToTwoDecimalPoints = formatToTwoDecimalPoints;
