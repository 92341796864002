"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setGlobalRole = exports.setToken = exports.setCurrentUserTenantId = exports.setCurrentUser = exports.currentUserSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const googleDrive_1 = require("@services/googleDrive");
const currentUser_1 = require("@services/currentUser");
const constants_1 = require("@appConstants/constants");
const initialState = {
    currentUser: undefined,
    tenantId: undefined,
    globalRole: undefined,
    token: undefined,
    currentApiVersion: '',
    changePasswordStatus: constants_1.REQUEST_STATUS.IDLE,
};
exports.currentUserSlice = (0, toolkit_1.createSlice)({
    name: 'currentUser',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setCurrentUserTenantId: (state, action) => {
            state.tenantId = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setGlobalRole: (state, action) => {
            state.globalRole = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(currentUser_1.fetchApiVersion.fulfilled, (state, action) => {
            if (action.payload) {
                state.currentApiVersion = action.payload;
            }
        });
        builder
            .addCase(currentUser_1.changePassword.pending, (state) => {
            state.changePasswordStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(currentUser_1.changePassword.fulfilled, (state, action) => {
            if (!action.payload) {
                (0, react_toastify_1.toast)('Error changing the password', {
                    type: 'error',
                    autoClose: 5000,
                });
                state.changePasswordStatus = constants_1.REQUEST_STATUS.FAILED;
                return;
            }
            else {
                (0, react_toastify_1.toast)('Password Updated Successfully', {
                    type: 'success',
                    autoClose: 5000,
                });
                state.changePasswordStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                return;
            }
        })
            .addCase(currentUser_1.changePassword.rejected, (state) => {
            state.changePasswordStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error changing the password', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(googleDrive_1.setUpGoogleDrive.pending, () => { })
            .addCase(googleDrive_1.setUpGoogleDrive.fulfilled, (state, action) => {
            if (!action.payload) {
                (0, react_toastify_1.toast)('Error setting up google drive. Please try again', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            window.location.replace(action.payload);
        })
            .addCase(googleDrive_1.setUpGoogleDrive.rejected, () => {
            (0, react_toastify_1.toast)('Error setting up google drive. Please try again', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
_a = exports.currentUserSlice.actions, exports.setCurrentUser = _a.setCurrentUser, exports.setCurrentUserTenantId = _a.setCurrentUserTenantId, exports.setToken = _a.setToken, exports.setGlobalRole = _a.setGlobalRole;
exports.default = exports.currentUserSlice.reducer;
