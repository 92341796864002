"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BIRTHDAY_TEMPLATE = exports.REVIEW_TEMPLATES = exports.TEMPLATES = exports.AVAILABLE_OPTIONS = exports.PATIENT_INFO = void 0;
exports.PATIENT_INFO = [
    {
        value: 'FirstName',
        display: 'First Name',
        sample: 'John',
    },
    {
        value: 'LastName',
        display: 'Last Name',
        sample: 'Smith',
    },
];
exports.AVAILABLE_OPTIONS = [
    {
        value: 'ProviderFirstName',
        display: 'Providers First Name',
        sample: 'Simon',
    },
    {
        value: 'ProviderLastName',
        display: 'Providers Last Name',
        sample: 'McSmiles',
    },
    {
        value: 'ProviderSuffix',
        display: 'Providers Suffix',
        sample: 'DDS',
    },
    {
        value: 'PracticeName',
        display: 'Practice Name',
        sample: 'Great Smiles Dental Office',
    },
    ...exports.PATIENT_INFO,
    {
        value: 'AppointmentDateTime',
        display: 'Appointment Date/Time',
        sample: '8:00am Tues, May 4th',
    },
];
exports.TEMPLATES = [
    {
        enabledPath: 'newAppointmentSmsTemplateEnabled',
        name: 'New Appointment Message',
        availableOptions: [
            ...exports.AVAILABLE_OPTIONS,
            {
                value: 'TenantName',
                display: 'Tenant Name',
                sample: 'Test Company',
            },
        ],
        path: 'newAppointmentSmsTemplate',
        default: 'Hi FirstName, your appointment at TenantName has been scheduled for AppointmentDateTime.',
        helpText: 'This message will be sent whenever an appointment is created.',
    },
    {
        enabledPath: 'confirmationSMSTemplateEnabled',
        name: 'Appointment Confirmation',
        availableOptions: exports.AVAILABLE_OPTIONS,
        path: 'confirmationSMSTemplate',
        default: 'Hi FirstName, we would like to confirm your appointment on AppointmentDateTime.',
        requiredSuffix: 'Please reply Yes or No',
        helpText: 'This message will be sent whenever an unconfirmed appointment is found.',
        confirmAppointmentDays: 'confirmAppointmentDays',
        helptext1: 'This message will be sent ',
        helptext2: 'before the appointment.',
    },
    {
        enabledPath: 'reminderSMSTemplateEnabled',
        name: 'Appointment Reminder',
        availableOptions: exports.AVAILABLE_OPTIONS,
        path: 'reminderSMSTemplate',
        reminderDays: 'reminderDays',
        default: 'Hi FirstName, we would like to confirm your appointment on AppointmentDateTime.',
        helpText: 'Choose the number of days before an upcoming appointment, to send this message.',
        helptext1: 'This reminder will be sent',
        helptext2: 'before the appointment.',
        reminderForConfirmedAppointments: "Don't send reminder if the appointment is confirmed.",
        isReminderForConfirmedAppointments: 'isReminderForConfirmedAppointments',
    },
    {
        enabledPath: 'secondReminderSMSTemplateEnable',
        name: 'Same Day Reminder',
        availableOptions: exports.AVAILABLE_OPTIONS,
        path: 'secondReminderSMSTemplate',
        secondReminderHours: 'secondReminderHours',
        default: 'Hi FirstName, we would like to confirm your appointment on AppointmentDateTime.',
        helpText: 'Choose the number of days before an upcoming appointment, to send this message.',
        helptext1: 'This 2nd reminder will be sent',
        helptext2: 'before the appointment.',
        reminderForConfirmedAppointments: "Don't send reminder if the appointment is confirmed.",
        isReminderForConfirmedAppointments: 'isSecondReminderForConfirmedAppointments',
    },
    {
        enabledPath: 'aptConfirmedSMSTemplateEnabled',
        name: 'Confirmation Auto Response',
        availableOptions: exports.AVAILABLE_OPTIONS,
        path: 'aptConfirmedSMSTemplate',
        default: 'Thank you for confirming, we look forward to seeing you',
        helpText: 'This message will be sent when an appointment is confirmed after a confirmation request.',
        previousMessage: 'Yes',
    },
    {
        enabledPath: 'aptCanceledSMSTemplateEnabled',
        name: 'Canceled Auto Response',
        availableOptions: exports.AVAILABLE_OPTIONS,
        path: 'aptCanceledSMSTemplate',
        default: 'Would you like to reschedule?',
        helpText: 'This message will be sent when an appointment is canceled after a confirmation request.',
        previousMessage: 'No',
    },
];
exports.REVIEW_TEMPLATES = [
    {
        enabledPath: 'finishedAppointmentSmsTemplateEnabled',
        name: 'Completed Appointment Message',
        reviewDays: 'reviewDays',
        availableOptions: [
            ...exports.AVAILABLE_OPTIONS,
            {
                value: 'TenantName',
                display: 'Tenant Name',
                sample: 'Test Company',
            },
        ],
        path: 'finishedAppointmentSmsTemplate',
        default: 'How was your experience with us? Was it good or bad?',
        helptext3: 'This message will be sent after',
        helptext4: 'an appointment is completed.',
        helptext1: 'This message will be sent ',
        helptext2: 'after the last review message.',
    },
    {
        enabledPath: 'goodReviewSmsTemplateEnabled',
        name: 'Positive Auto Response',
        availableOptions: [
            ...exports.AVAILABLE_OPTIONS,
            {
                value: 'TenantName',
                display: 'Tenant Name',
                sample: 'Test Company',
            },
        ],
        path: 'goodReviewSmsTemplate',
        default: `Thank you for your positive review! We're delighted to hear that you had a great experience with us.`,
        helpText: 'This message will be sent whenever respond good.',
    },
    {
        enabledPath: 'badReviewSmsTemplateEnabled',
        name: 'Negative Auto Response',
        availableOptions: [
            ...exports.AVAILABLE_OPTIONS,
            {
                value: 'TenantName',
                display: 'Tenant Name',
                sample: 'Test Company',
            },
        ],
        path: 'badReviewSmsTemplate',
        default: `We're truly sorry to hear about your recent experience with us.`,
        helpText: 'This message will be sent whenever respond bad.',
    },
];
exports.BIRTHDAY_TEMPLATE = {
    enabledPath: 'birthdayMessageTemplateEnabled',
    name: 'Birthday Message',
    availableOptions: exports.PATIENT_INFO,
    path: 'birthdayMessageTemplate',
    default: 'Hi FirstName, on your special day, We wish you to be happy and cheerful! May your eyes be filled with happiness, your heart be filled with love, your mind with new ideas, and your life with success and exciting adventures.',
    helpText: 'This message will be sent whenever a patient has a birthday.',
};
