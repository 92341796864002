"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const typography_1 = require("@styles/typography");
const TenantFeatures = ({ values, onChangeTheme,
//  touched, errors, basicInput, handleSwitchChange,
//   archived,
 }) => {
    const [isApexTheme, setIsApexTheme] = (0, react_1.useState)(false);
    const handleToggleTheme = (event) => {
        const newTheme = event.target.checked ? 'apex' : 'treat';
        setIsApexTheme(event.target.checked);
        onChangeTheme(newTheme);
    };
    (0, react_1.useEffect)(() => {
        if (values.theme) {
            setIsApexTheme(values.theme === 'apex');
        }
    }, [values.theme]);
    return ((0, jsx_runtime_1.jsxs)(material_1.Paper, { className: 'tenantFeatures', children: [(0, jsx_runtime_1.jsx)(typography_1.MainTypography, { className: 'title', children: "Tenant Features" }), (0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'feature', children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: 'h6', children: "Theme" }), (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { label: isApexTheme ? 'Apex Theme' : 'Treat Theme', control: (0, jsx_runtime_1.jsx)(material_1.Switch, { checked: isApexTheme, name: 'theme', onChange: handleToggleTheme }) })] })] }));
};
exports.default = TenantFeatures;
