"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabCardContainer = exports.TabContainer = exports.TabWrapper = exports.CardMenu = void 0;
const material_1 = require("@mui/material");
const styles_1 = require("@mui/material/styles");
exports.CardMenu = (0, styles_1.styled)(material_1.Card)(({ theme, show }) => ({
    zIndex: 999,
    borderRadius: 8,
    background: theme.palette.custom.surface01,
    border: 'none',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
        display: show == 'true' ? '' : 'none',
        position: 'absolute',
    },
    '>div': {
        padding: 0,
    },
}));
exports.TabWrapper = (0, styles_1.styled)(material_1.Tabs)(({ theme }) => ({
    '& .css-3r82j0-MuiTabs-flexContainer': {
        alignItems: 'start',
    },
    '& .MuiTabs-flexContainer': {
        [theme.breakpoints.down('isTab')]: {
            gap: '10px ',
            paddingLeft: '20px',
        },
    },
    '& .MuiTabs-indicator': {
        display: 'none',
    },
    '& .Mui-selected': {
        backgroundColor: `${(0, material_1.alpha)(theme.palette.primary.main, 0.12)} !important`,
        width: '100%',
        [theme.breakpoints.down('isTab')]: {
            borderRadius: '24px',
        },
    },
    '& .MuiTab-root': {
        transition: 'background-color 0.3s ease',
        borderBottom: `1px solid ${theme.palette.custom.light03}`,
        width: '100%',
        alignItems: 'start',
        textTransform: 'capitalize',
        color: theme.palette.custom.dark01,
        [theme.breakpoints.down('isTab')]: {
            borderRadius: '24px',
            backgroundColor: theme.palette.custom.light01,
            width: 'max-content',
            borderBottom: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            width: '125px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'block',
        },
        '& .MuiTabs-scrollButtons': {
            display: 'none !important',
        },
        '& .MuiTabs-scrollButtonsHideMobile ': {
            display: 'none',
        },
    },
}));
exports.TabContainer = (0, styles_1.styled)(material_1.Box)(() => ({
    height: '100%',
}));
exports.TabCardContainer = (0, styles_1.styled)(material_1.Card)(() => ({
    height: 'calc(100vh - 79px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
