"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const material_1 = require("@mui/material");
const kioskSlice_1 = require("@redux/reducers/kioskSlice");
const AssignedExtension = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { currentTenantSettings } = (0, react_redux_1.useSelector)((state) => state.tenantsReducer);
    const { localExtension } = (0, react_redux_1.useSelector)((state) => state.kioskReducer);
    const availableExtensions = (0, react_1.useMemo)(() => {
        if (!currentTenantSettings) {
            return ['None'];
        }
        let extensions = [];
        if (!currentTenantSettings.officeExtensions) {
            for (let i = 1; i < 10; i++) {
                extensions.push((100 + i).toString());
                extensions.push(`${(100 + i).toString()}m`);
            }
        }
        else {
            extensions = currentTenantSettings.officeExtensions.split(',');
        }
        return ['None', ...extensions];
    }, [currentTenantSettings]);
    const handleChange = (event) => {
        dispatch((0, kioskSlice_1.setExtension)(event.target.value));
    };
    if (!currentTenantSettings || !availableExtensions) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
    return ((0, jsx_runtime_1.jsxs)(material_1.FormControl, { variant: 'standard', sx: { ml: 2, mr: 2 }, children: [(0, jsx_runtime_1.jsx)(material_1.InputLabel, { id: 'ext-label', children: "Ext" }), (0, jsx_runtime_1.jsx)(material_1.Select, { labelId: 'ext-label', id: 'ext-label-select', value: localExtension, onChange: handleChange, label: 'Ext', children: availableExtensions.map((ex) => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: ex, children: ex }, ex))) })] }));
};
exports.default = AssignedExtension;
