"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUserAppointments = exports.markAppointmentConfirm = exports.fetchAppointments = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const axiosHelper_1 = require("@utils/axiosHelper");
const initialize_instance_1 = require("@apis/initialize.instance");
exports.fetchAppointments = (0, toolkit_1.createAsyncThunk)('appointments/fetchAppointments', ({ startDate, endDate }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { currentUser, tenantId, token } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!startDate || !endDate) {
            return Promise.reject('noDatesFound');
        }
        const response = yield initialize_instance_1.appointmentInstance.get(tenantId, (0, axiosHelper_1.addTokenToConfig)({ params: { startDate, endDate } }, token));
        if (response && response.data) {
            return response.data;
        }
        return [];
    }
    catch (err) {
        throw err;
    }
}));
exports.markAppointmentConfirm = (0, toolkit_1.createAsyncThunk)('appointments/markAppointmentConfirm', ({ appointmentId }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { currentUser, tenantId, token } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!appointmentId) {
            return Promise.reject('noAppointmentId');
        }
        const response = yield initialize_instance_1.appointmentInstance.patch(`/set-status/${tenantId}/${appointmentId}`, { aptStatus: 2 }, (0, axiosHelper_1.addTokenToConfig)({}, token));
        if (response && response.status === 204) {
            const { selectedDate } = storeState.appointmentReducer;
            const startDate = new Date(selectedDate);
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date(selectedDate);
            endDate.setHours(24, 0, 0, 0);
            thunkApi.dispatch((0, exports.fetchAppointments)({
                startDate,
                endDate,
            }));
            return true;
        }
        return false;
    }
    catch (err) {
        throw err;
    }
}));
exports.fetchUserAppointments = (0, toolkit_1.createAsyncThunk)('appointments/fetchUserAppointments', (patientId, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const storeState = thunkApi.getState();
        const { currentUser, tenantId, token } = storeState.currentUserReducer;
        if (!tenantId) {
            return Promise.reject('noTenantId');
        }
        if (!currentUser) {
            return Promise.reject('noCurrentUser');
        }
        if (!token) {
            return Promise.reject('noToken');
        }
        if (!patientId) {
            return Promise.reject('nopatientId');
        }
        const response = yield initialize_instance_1.appointmentInstance.get(`${tenantId}/${patientId}`, (0, axiosHelper_1.addTokenToConfig)({}, token));
        return response.data;
    }
    catch (err) {
        throw err;
    }
}));
