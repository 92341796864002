"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const material_1 = require("@mui/material");
const kioskSlice_1 = require("@redux/reducers/kioskSlice");
require("./kioskForm.scss");
const KioskForm = ({ kioskNumber }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { formsToFillOut } = (0, react_redux_1.useSelector)((state) => state.kioskReducer);
    const [currentForm, setCurrentForm] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        if (formsToFillOut && formsToFillOut.length > 0) {
            setCurrentForm(formsToFillOut[0]);
        }
        else {
            setCurrentForm(undefined);
        }
    }, [formsToFillOut]);
    const handleNextForm = () => {
        if (formsToFillOut && formsToFillOut.length > 0) {
            dispatch((0, kioskSlice_1.shiftFormsToFillOut)());
        }
    };
    const renderButton = () => {
        if ((formsToFillOut === null || formsToFillOut === void 0 ? void 0 : formsToFillOut.length) && formsToFillOut.length > 0) {
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(material_1.Button, { variant: 'contained', color: 'primary', onClick: handleNextForm, children: formsToFillOut.length < 2 ? 'Done' : 'Next' }), (0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: 'h6', children: ["KIOSK - ", kioskNumber] })] }));
        }
        return null;
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [' ', renderButton() && (0, jsx_runtime_1.jsx)(material_1.Box, { className: 'buttons', children: renderButton() }), currentForm ? ((0, jsx_runtime_1.jsx)("iframe", { className: 'iframe', src: currentForm, title: 'Kiosk Form' })) : ((0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: 'h2', children: ["KIOSK - ", kioskNumber] }))] }));
};
exports.default = KioskForm;
