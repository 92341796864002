"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const icons_material_1 = require("@mui/icons-material");
const OfficeExtensions = ({ extensions, basicInput, isSubmitting, handleExtensionAdd, error, helperText, archived, }) => {
    const [extension, setExtension] = (0, react_1.useState)('');
    const handleChange = (event) => setExtension(event.target.value);
    const onAdd = () => {
        if (extension) {
            handleExtensionAdd(extensions ? extensions + ',' + extension : extension);
            setExtension('');
        }
    };
    const removeExtension = (removeIndex) => {
        const values = [];
        extensions.split(',').forEach((value, index) => {
            if (index !== removeIndex) {
                values.push(value);
            }
        });
        handleExtensionAdd(values.join(','));
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'extensionWrapper', children: [(0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'field', children: [(0, jsx_runtime_1.jsx)(material_1.TextField, Object.assign({}, basicInput, { id: 'officeExtension', name: 'officeExtension', label: 'Add New Office Extension', type: 'name', value: extension, onChange: handleChange, error: error })), (0, jsx_runtime_1.jsx)(material_1.Button, { variant: 'contained', color: 'primary', onClick: onAdd, disabled: isSubmitting || archived, children: "ADD" })] }), extensions ? ((0, jsx_runtime_1.jsx)(material_1.Box, { className: 'extensions', children: extensions.split(',').map((value, index) => ((0, jsx_runtime_1.jsxs)(material_1.Box, { sx: {
                        cursor: archived ? 'default !important' : 'pointer',
                        '&:hover': { boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2) !important' },
                    }, className: `extension ${isSubmitting && 'delete'}`, children: [(0, jsx_runtime_1.jsx)("p", { children: value }), (0, jsx_runtime_1.jsx)(material_1.Button, { disabled: archived, className: 'deleteExtensionButton', children: (0, jsx_runtime_1.jsx)(icons_material_1.DeleteForeverRounded, { onClick: () => removeExtension(index) }) })] }, index))) })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {})), error && helperText ? (0, jsx_runtime_1.jsx)("p", { className: 'error', children: helperText }) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {})] }));
};
exports.default = OfficeExtensions;
