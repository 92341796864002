"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const subComponents_1 = require("./subComponents");
require("./currentConversation.scss");
const CurrentConversation = ({ handleDrawerClose }) => {
    const { currentConversation, getMessagesStatus, isSending, isMarking } = (0, react_redux_1.useSelector)((state) => state.conversationsReducer);
    const { basicUsers } = (0, react_redux_1.useSelector)((state) => state.usersReducer);
    if (!currentConversation) {
        return (0, jsx_runtime_1.jsx)(subComponents_1.NoConversation, {});
    }
    return ((0, jsx_runtime_1.jsx)(subComponents_1.ShowConversation, { currentConversation: currentConversation, getMessagesStatus: getMessagesStatus, isSending: isSending, isMarking: isMarking, basicUsers: basicUsers, handleDrawerClose: handleDrawerClose }));
};
exports.default = CurrentConversation;
