"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setShowAll = exports.setShowUnConfirmed = exports.setShowConfirmed = exports.setCurrentAppointment = exports.setSelectedDate = exports.appointmentsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const appointments_1 = require("@services/appointments");
const constants_1 = require("@appConstants/constants");
const initialState = {
    selectedDate: new Date(),
    getAppointmentsStatus: constants_1.REQUEST_STATUS.PENDING,
    markAppointmentsStatus: constants_1.REQUEST_STATUS.IDLE,
    getUserAppointmentsStatus: constants_1.REQUEST_STATUS.IDLE,
    appointments: [],
    currentAppointment: undefined,
    showConfirmed: false,
    showUnconfirmed: false,
    showAll: true,
    userAppointments: [],
};
exports.appointmentsSlice = (0, toolkit_1.createSlice)({
    name: 'appointments',
    initialState,
    reducers: {
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setCurrentAppointment: (state, action) => {
            state.currentAppointment = action.payload;
        },
        setShowConfirmed: (state, action) => {
            state.showConfirmed = action.payload;
        },
        setShowUnConfirmed: (state, action) => {
            state.showUnconfirmed = action.payload;
        },
        setShowAll: (state, action) => {
            state.showAll = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(appointments_1.fetchAppointments.pending, (state) => {
            state.currentAppointment = undefined;
            state.getAppointmentsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(appointments_1.fetchAppointments.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.appointments = action.payload;
                state.getAppointmentsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getAppointmentsStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the appointments', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(appointments_1.fetchAppointments.rejected, (state) => {
            state.getAppointmentsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the appointments', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(appointments_1.markAppointmentConfirm.pending, (state) => {
            state.markAppointmentsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(appointments_1.markAppointmentConfirm.fulfilled, (state, action) => {
            if (!action.payload) {
                state.markAppointmentsStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error occur while marking the appointment confirmed', {
                    type: 'error',
                    autoClose: 5000,
                });
                return;
            }
            state.markAppointmentsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('The appointment has been marked as confirmed', {
                type: 'success',
                autoClose: 5000,
            });
            state.appointments = [];
            state.currentAppointment = undefined;
        })
            .addCase(appointments_1.markAppointmentConfirm.rejected, (state) => {
            state.markAppointmentsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error occur while marking the appointment confirmed', {
                type: 'error',
                autoClose: 5000,
            });
        });
        builder
            .addCase(appointments_1.fetchUserAppointments.pending, (state) => {
            state.userAppointments = [];
            state.getUserAppointmentsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(appointments_1.fetchUserAppointments.fulfilled, (state, action) => {
            if (action.payload && Array.isArray(action.payload)) {
                state.userAppointments = action.payload;
                state.getUserAppointmentsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getUserAppointmentsStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the user appointments', {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        })
            .addCase(appointments_1.fetchUserAppointments.rejected, (state) => {
            state.getUserAppointmentsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the user appointments', {
                type: 'error',
                autoClose: 5000,
            });
        });
    },
});
_a = exports.appointmentsSlice.actions, exports.setSelectedDate = _a.setSelectedDate, exports.setCurrentAppointment = _a.setCurrentAppointment, exports.setShowConfirmed = _a.setShowConfirmed, exports.setShowUnConfirmed = _a.setShowUnConfirmed, exports.setShowAll = _a.setShowAll;
exports.default = exports.appointmentsSlice.reducer;
