"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const react_redux_1 = require("react-redux");
const forms_1 = require("@services/forms");
const reservedNames = ['connectcategorytype', 'connectcode', 'connectuserid', 'connectdocumenttype'];
const TenantFormQuestion = ({ question, formId, canAssignQuestions }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { mappings } = (0, react_redux_1.useSelector)((state) => state.formReducer);
    const [mappingChooserOpen, setMappingChooserOpen] = (0, react_1.useState)(false);
    const [chosenValueMapping, setChosenValueMapping] = (0, react_1.useState)();
    const handleTypeClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setMappingChooserOpen(true);
    });
    const mappingsForType = (0, react_1.useMemo)(() => {
        if (canAssignQuestions && mappings && question) {
            return mappings[question.type];
        }
        return undefined;
    }, [canAssignQuestions, mappings, question]);
    const handleDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        if (question && question.name) {
            const questionData = { name: question.name.replace(/connect/, '_') };
            if (questionData) {
                dispatch((0, forms_1.updateQuestion)({
                    formId,
                    questionId: question.qid,
                    questionData,
                }));
            }
        }
    });
    const handleMappingChooserClose = (newValue) => {
        setMappingChooserOpen(false);
        if (!newValue) {
            return;
        }
        if (newValue === 'none') {
            handleDelete();
        }
        else {
            if (Array.isArray(mappingsForType) && mappingsForType.length > 0) {
                let questionData;
                for (let i = 0; i < mappingsForType.length; i++) {
                    const element = mappingsForType[i];
                    if (element.name == newValue) {
                        questionData = element;
                    }
                }
                if (questionData) {
                    dispatch((0, forms_1.updateQuestion)({ formId, questionId: question.qid, questionData }));
                }
            }
        }
    };
    const assignedConnectType = (0, react_1.useMemo)(() => {
        if (canAssignQuestions && Array.isArray(mappingsForType)) {
            const assignedMapping = mappingsForType.find((element) => element.name === question.name);
            setChosenValueMapping(assignedMapping === null || assignedMapping === void 0 ? void 0 : assignedMapping.name);
            if (assignedMapping) {
                return (0, jsx_runtime_1.jsx)(material_1.Chip, { label: assignedMapping.displayName, onDelete: handleDelete });
            }
            return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
        }
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }, [question, canAssignQuestions, mappingsForType]);
    const assignButton = (0, react_1.useMemo)(() => {
        if (mappings && mappings[question.type] && canAssignQuestions) {
            return (0, jsx_runtime_1.jsx)(material_1.Chip, { label: 'Assign', color: 'primary', clickable: true, onClick: handleTypeClick });
        }
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }, [mappings, question, canAssignQuestions]);
    if (reservedNames.indexOf(question.name) > -1) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, { className: 'tenantFormQuestion', children: [(0, jsx_runtime_1.jsx)(material_1.Box, { children: (0, jsx_runtime_1.jsxs)(material_1.Typography, { variant: 'h6', children: [question.text, '  ', assignedConnectType] }) }), assignButton, Array.isArray(mappingsForType) && mappingsForType.length > 0 && ((0, jsx_runtime_1.jsx)(FormMappingChooser, { isOpen: mappingChooserOpen, mappings: mappingsForType, onClose: handleMappingChooserClose, value: chosenValueMapping }))] }));
};
const FormMappingChooser = ({ isOpen, mappings, onClose, value }) => {
    const [formValue, setFormValue] = (0, react_1.useState)(value || 'none');
    const handleCancel = () => {
        onClose();
    };
    const handleOk = () => {
        onClose(formValue);
    };
    const handleChange = (event) => {
        setFormValue(event.target.value);
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Dialog, { open: isOpen, maxWidth: 'xs', keepMounted: true, children: [(0, jsx_runtime_1.jsx)(material_1.DialogTitle, { id: 'confirmation-dialog-title', children: "Mapping Options" }), (0, jsx_runtime_1.jsx)(material_1.DialogContent, { dividers: true, children: (0, jsx_runtime_1.jsxs)(material_1.RadioGroup, { "aria-label": 'ringtone', name: 'ringtone', value: formValue, onChange: handleChange, children: [(0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { value: 'none', control: (0, jsx_runtime_1.jsx)(material_1.Radio, {}), label: 'none' }, 'none'), mappings.map((map) => ((0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { value: map.name, control: (0, jsx_runtime_1.jsx)(material_1.Radio, {}), label: map.displayName }, map.name)))] }) }), (0, jsx_runtime_1.jsxs)(material_1.DialogActions, { children: [(0, jsx_runtime_1.jsx)(material_1.Button, { onClick: handleCancel, color: 'primary', children: "Cancel" }), (0, jsx_runtime_1.jsx)(material_1.Button, { onClick: handleOk, color: 'primary', children: "Okay" })] })] }));
};
exports.default = TenantFormQuestion;
